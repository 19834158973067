/*************************************************/
/* This file was automatically generated by sass */
/* Don't edit it directly                        */
/*************************************************/
/* Clearfix */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 only */
.clearfix {
  *zoom: 1;
}

body.lock-position {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
}

/*=============================================
=    Css Negative Margins For Bootstrap v5    =
=============================================*/
/*---------- SECTION Margins  ----------*/
/* ANCHOR negative m */
.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

/* end negative m */
/* ANCHOR negative mx */
.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

/* end negative mx */
/* ANCHOR negative my */
.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

/* end negative my */
/* ANCHOR negative mt */
.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

/* end negative mt */
/* ANCHOR negative me */
.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

/* end negative me */
/* ANCHOR negative mb */
.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

/* end negative mb */
/* ANCHOR negative ms */
.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

/* end negative ms */
/*----------  !SECTION  ----------*/
/*----------  SECTION MIN 576 - SM  ----------*/
@media (min-width: 576px) {
  /* ANCHOR negative m-sm */
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  /* end negative m-sm */
  /* ANCHOR negative mx-sm */
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  /* end negative mx-sm */
  /* ANCHOR negative my-sm */
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  /* end negative my-sm */
  /* ANCHOR negative mt-sm */
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  /* end negative mt-sm */
  /* ANCHOR negative me-sm */
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  /* end negative me-sm */
  /* ANCHOR negative mb-sm */
  .mb-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-right: -3rem !important;
  }
  /* end negative mb-sm */
  /* ANCHOR negative ms-sm */
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  /* end negative ms-sm */
}
/*----------  !SECTION  ----------*/
/*----------  SECTION MIN 768 - MD  ----------*/
@media (min-width: 768px) {
  /* ANCHOR negative m-md */
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  /* end negative m-md */
  /* ANCHOR negative mx-md */
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  /* end negative mx-md */
  /* ANCHOR negative my-md */
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  /* end negative my-md */
  /* ANCHOR negative mt-md */
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  /* end negative mt-md */
  /* ANCHOR negative me-md */
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  /* end negative me-md */
  /* ANCHOR negative mb-md */
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  /* end negative mb-md */
  /* ANCHOR negative ms-md */
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  /* end negative ms-md */
}
/*----------  !SECTION  ----------*/
/*----------  SECTION MIN 992 - LG  ----------*/
@media (min-width: 992px) {
  /* ANCHOR negative m-lg */
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  /* end negative m-lg */
  /* ANCHOR negative mx-lg */
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  /* end negative mx-lg */
  /* ANCHOR negative my-lg */
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  /* end negative my-lg */
  /* ANCHOR negative mt-lg */
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  /* end negative mt-lg */
  /* ANCHOR negative me-lg */
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  /* end negative mx-lg */
  /* ANCHOR negative mb-lg */
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  /* end negative mb-lg */
  /* ANCHOR negative ms-lg */
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  /* end negative ms-lg */
}
/*----------  !SECTION  ----------*/
/*----------  SECTION MIN 1200 - XL  ----------*/
@media (min-width: 1200px) {
  /* ANCHOR negative m-xl */
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  /* end negative m-xl */
  /* ANCHOR negative mx-xl */
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  /* end negative mx-xl */
  /* ANCHOR negative my-xl */
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  /* end negative my-xl */
  /* ANCHOR negative mt-xl */
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  /* end negative mt-xl */
  /* ANCHOR negative me-xl */
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  /* end negative mx-xl */
  /* ANCHOR negative mb-xl */
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  /* end negative mb-xl */
  /* ANCHOR negative ms-xl */
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  /* end negative ms-lg */
}
/*----------  !SECTION  ----------*/
/*----------  SECTION MIN 1400 - XXL  ----------*/
@media (min-width: 1400px) {
  /* ANCHOR negative m-xxl */
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  /* end negative m-xxl */
  /* ANCHOR negative mx-xxl */
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  /* end negative mx-xxl */
  /* ANCHOR negative my-xxl */
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  /* end negative my-xxl */
  /* ANCHOR negative mt-xxl */
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  /* end negative mt-xxl */
  /* ANCHOR negative me-xxl */
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  /* end negative me-xxl */
  /* ANCHOR negative mb-xxl */
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  /* end negative mb-xxl */
  /* ANCHOR negative ms-xxl */
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  /* end negative ms-xxl */
}
/*----------  !SECTION  ----------*/
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  color: #000;
  -webkit-font-smoothing: antialiased;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
}
@media (max-width: 767.98px) {
  html, body {
    font-size: 15px;
  }
}

.main {
  min-height: 100%;
}

h1, h2, h3, h4, h5 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
  color: #2a0d7d;
  margin-bottom: 0;
  line-height: 1.1;
}

h1 {
  font-size: 2.8rem;
  margin-bottom: 0.6em;
  margin-top: 0.3em;
}
@media (max-width: 767.98px) {
  h1 {
    font-size: 2.4rem;
  }
}

h2 {
  font-size: 2.25rem;
  margin-bottom: 0.4em;
}
@media (max-width: 767.98px) {
  h2 {
    font-size: 2.2rem;
  }
}

h3 {
  margin-bottom: 0.4em;
}

a {
  color: #b426fb;
  text-decoration: none;
}
a:hover {
  color: #b426fb;
  text-decoration: underline;
}

ul.bullets {
  padding-left: 1.75em;
}
ul.bullets li {
  margin-bottom: 0.3em;
}

.lead {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #2a0d7d;
}

.medium {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #2a0d7d;
  font-size: 1.1em;
}

strong, strong.medium {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
}

.superstrong {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #2a0d7d;
  text-transform: uppercase;
}

.subtitle {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1.35em;
  text-transform: uppercase;
  color: #2a0d7d;
}
@media (max-width: 767.98px) {
  .subtitle {
    font-size: 1.2em;
  }
}
.subtitle .subtitle-small {
  font-size: 0.85em;
}

.highlighted {
  display: inline-block;
  background-color: #24ddb6;
  color: black;
  padding: 0.2em 0.4em;
  font-weight: 700;
}

.mixed-case {
  text-transform: none;
}

a.btn {
  text-transform: uppercase;
  font-weight: bold;
}
a.btn:hover {
  text-decoration: none;
}

.btn-primary {
  background-color: #2a0d7d;
  border-color: #2a0d7d;
  color: #fff;
}
.btn-primary:hover {
  background-color: #1a084f;
  border-color: #1a084f;
  color: #fff;
}

.btn-highlighted {
  background-color: #b426fb;
  border-color: #b426fb;
  color: #fff;
}
.btn-highlighted:hover {
  background-color: #9d04ea;
  border-color: #9d04ea;
  color: #fff;
}

.extra-padding {
  padding: 0 40px;
}
@media (min-width: 1600px) {
  .extra-padding {
    padding: 0 100px;
  }
}
@media (max-width: 1199.98px) {
  .extra-padding {
    padding: 0;
  }
}

.extra-padding-l {
  padding-left: 40px;
}
@media (min-width: 1600px) {
  .extra-padding-l {
    padding-left: 100px;
  }
}
@media (max-width: 1199.98px) {
  .extra-padding-l {
    padding-left: 0;
  }
}

.extra-padding-r {
  padding-right: 40px;
}
@media (min-width: 1600px) {
  .extra-padding-r {
    padding-right: 100px;
  }
}
@media (max-width: 1199.98px) {
  .extra-padding-r {
    padding-right: 0;
  }
}

.panel-padding {
  padding: 0 15px;
}
@media (min-width: 1600px) {
  .panel-padding {
    padding-left: 70px;
  }
}
@media (max-width: 1199.98px) {
  .panel-padding {
    padding: 0;
  }
}

.panel-warning {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1em;
  margin: 0.25em;
}
.panel-warning .lead {
  color: #fb0577;
}

.panel-bordered {
  padding: 1em;
  border: 10px solid #24ddb6;
  margin: 0.25em;
}

.panel-info {
  padding: 0.7em;
  background-color: #eaeaea;
}

.panel-dark {
  padding: 1.15em 1.65em;
  background-color: #2a0d7d;
  color: #fff;
}
.panel-dark h1, .panel-dark h2, .panel-dark h3, .panel-dark h4, .panel-dark h5 {
  color: #fff;
}
.panel-dark .medium {
  color: #fff;
}

.panel-white {
  background-color: #fff;
}

.mt-6 {
  margin-top: 5rem !important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}

.recording {
  border: 10px solid #24ddb6;
}

.photo-speaker {
  border: 8px solid #2a0d7d;
}

.text-orange {
  color: #df7800;
}

.text-indigo {
  color: #2a0d7d;
}

.animatedbg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;
}
.animatedbg iframe {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  width: 40%;
  height: 100%;
  border: none;
  border-left: 20px solid #fff;
  border-right: 20px solid #fff;
}
@media (max-width: 1199.98px) {
  .animatedbg iframe {
    width: 45%;
  }
}

.animatedbg-placeholder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  width: 50%;
  height: 100%;
}

.mainlogo-wrapper-2022 {
  max-width: 500px;
  margin: 0 auto;
}

.mainlogo-wrapper-2024 {
  max-width: 450px;
  margin: 0 auto;
}

.mainlogo-2022 {
  width: 100%;
  height: 0;
  padding: 0;
  padding-bottom: 86%;
  position: relative;
  margin: 0;
  background-image: url("../../static/img/2022/logo-matrix-x-imaginary-2022-colors.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0;
  border: none;
}

.mainlogo-2024 {
  width: 100%;
  height: 0;
  padding: 0;
  padding-bottom: 98%;
  position: relative;
  margin: 0;
  background-image: url("../../static/img/2024/mxi-2024-logo.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0;
  border: none;
}

.navbar-mxi {
  background-color: #eaeaea;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 900;
  margin-bottom: 2em;
}
.navbar-mxi .nav-link {
  color: #2a0d7d;
}
.navbar-mxi .nav-link.active {
  color: #8425fb;
}

@media (min-width: 992px) {
  .navbar-expand-xl .navbar-nav .nav-link,
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.2em 1em;
  }
}
.nav {
  margin-bottom: 2em;
  margin-top: 1em;
}
.nav .nav-link {
  background-color: #b426fb;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 900;
  margin-bottom: 0.25em;
  background-image: url("../../static/img/chevron-right.svg");
  background-repeat: no-repeat;
  background-position: 0.7em center;
  background-size: 0.85em;
  padding: 0.5em 1em 0.5em 2.1em;
}

.panel-toc {
  padding: 1.2em 0.8em 1.1em;
  background-color: #eaeaea;
  margin: 1em 0 4em;
}
.panel-toc ul {
  padding-left: 1.2em;
}
.panel-toc ul li {
  padding-bottom: 0.1em;
}
.panel-toc ul li a {
  color: #2a0d7d;
}

.countdown {
  margin: 0 auto;
  text-align: center;
  padding-top: 1em;
}
.countdown .countdown-item {
  display: inline-block;
  text-align: center;
  background-color: #fff;
  color: #2a0d7d;
  height: 5em;
  width: 5em;
  margin-right: 0.7em;
  margin-bottom: 0.7em;
}
.countdown .countdown-item:last-child {
  margin-right: 0;
}
.countdown .countdown-item .value {
  display: block;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 2em;
  line-height: 1.2;
  margin-top: 0.3em;
}
.countdown .countdown-item .unit {
  display: block;
  font-size: 0.9em;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
  text-transform: uppercase;
}
@media (max-width: 991.98px) {
  .countdown .countdown-item {
    height: 4em;
    width: 4em;
    margin-right: 0.4em;
    margin-bottom: 0.4em;
  }
  .countdown .countdown-item .value {
    font-size: 2em;
  }
  .countdown .countdown-item .unit {
    font-size: 0.75em;
  }
}
@media (max-width: 575.98px) {
  .countdown .countdown-item {
    width: 3.6em;
    margin-right: 0.2em;
  }
  .countdown .countdown-item .unit {
    font-size: 0.7em;
  }
}

.section-turquoise {
  background-color: #24ddb6;
}
.section-turquoise h2 {
  color: #fff;
}

.section-gray {
  background-color: #eaeaea;
}

.section-orange {
  background-color: #ebbc2b;
}

.section-header .logo-horizontal {
  display: block;
  font-size: 0;
  background-image: url("../../static/img/logo-mxi-horizontal.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left top;
  width: 100%;
  height: 100px;
  margin-bottom: 1em;
}
@media (max-width: 575.98px) {
  .section-header .logo-horizontal {
    height: 60px;
  }
}
.section-header .logo-area {
  margin: 1.5em 0 3.5em;
}
@media (max-width: 767.98px) {
  .section-header .logo-area {
    margin-bottom: 1.5em;
  }
}

.section-footer {
  position: relative;
  margin-top: 5em;
  padding: 2em 0;
  background-color: #2a0d7d;
  color: #fff;
}
.section-footer a {
  color: #fff;
}
.section-footer a:hover {
  color: #fff;
}

ul.footer-links {
  padding: 0;
  margin: 0;
}
ul.footer-links li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-credits {
  font-size: 14px;
}

ul.organizers {
  margin: 0;
  padding: 0;
  font-size: 0;
}
ul.organizers li {
  list-style: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 30%;
  height: auto;
  margin-right: 5%;
}
ul.organizers li:last-child {
  margin-right: 0;
}
@media (max-width: 767.98px) {
  ul.organizers li {
    width: 45%;
    margin-bottom: 20px;
  }
}
ul.organizers .organizer-logo {
  display: block;
  display: block;
  text-align: left;
  text-indent: -100em;
  white-space: nowrap;
  overflow: hidden;
  background-repeat: no-repeat;
  padding-bottom: 60%;
  background-size: contain;
  background-position: center center;
}
ul.organizers .ihp {
  background-image: url("../../static/img/2022/logo-ihp-mp.svg");
  width: 100%;
  height: 0;
}
ul.organizers .momath {
  background-image: url("../../static/img/2022/logo-momath.jpg");
  width: 100%;
  height: 0;
}
ul.organizers .imaginary {
  background-image: url("../../static/img/2022/logo-imaginary-openmath.png");
  width: 100%;
  height: 0;
}
ul.organizers .srs {
  background-image: url("../../static/img/2024/logo-srs.png");
  width: 100%;
  height: 0;
}

.organizers-panel {
  position: relative;
  padding: 2em 0;
  background-color: #fff;
}

ul.organizers-2024 .srs {
  width: 90%;
}
ul.organizers-2024 .momath {
  width: 97%;
}

.bootstrap-select.tz-selector {
  min-width: 200px;
}

@media (max-width: 767.98px) {
  .form-inline .bootstrap-select {
    width: 100% !important;
  }
}
.form-inline .bootstrap-select > .dropdown-toggle {
  background-color: #eaeaea;
  border-color: #eaeaea;
}

.schedule {
  display: none;
}
.schedule.ready {
  display: block;
}
.schedule.hide-tz .schedule-content .col-time .timezone {
  display: none;
}
.schedule .table {
  margin-bottom: 2rem;
}
.schedule tr.break {
  background: repeating-linear-gradient(302deg, #fff, #fff 5px, #e4e4e4 5px, #e4e4e4 6px);
}
.schedule tr.break td {
  background-color: transparent;
}
.schedule td.col-time {
  width: 120px;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .schedule td.col-time {
    width: 100px;
  }
}
.schedule h3 {
  margin-bottom: 0.6em;
}
.schedule .time {
  font-weight: bold;
}
.schedule .timezone {
  font-size: 0.85em;
  margin-top: 0.1em;
}
.schedule .time-alt {
  font-weight: bold;
  font-size: 0.9em;
}
.schedule .highlighted {
  background-color: #aff9e9;
  color: #2a0d7d;
  padding: 0.2em 0.6em;
  margin-left: -0.2em;
}
.schedule .medium {
  font-size: 1.2em;
}
.schedule .talk-title {
  color: #2a0d7d;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: -0.9rem;
}

.session {
  margin-bottom: 3rem;
}
.session .title {
  font-size: 1.5rem;
  margin-bottom: 0.1em;
}
.session .presenter {
  font-size: 1.33rem;
  font-weight: bold;
}
.session .affiliation {
  font-size: 1.33rem;
}
.session .description {
  margin-top: 1em;
}