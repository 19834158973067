.section-turquoise {
  background-color: $color-turquoise;
  h2 {
    color: $color-white
  }
}

.section-gray {
  background-color: $color-gray-light;
}

.section-orange {
  background-color: $color-orange;
}
