$color-black-real: #000;
$color-white-real: #fff;

$color-indigo-dark: #2a0d7d;
$color-turquoise: #24ddb6;
$color-violet-blue: #5724fb;
$color-violet: #8425fb;
$color-violet-red: #b426fb;

$color-orange: #ebbc2b;
$color-orange-dark: #df7800;

$color-blue-bright: #508cff;
$color-violet-red-bright: #e64eff;
$color-turquoise-light: #aff9e9;

$color-gray-light: #eaeaea;

$color-black: $color-black-real;
$color-white: $color-white-real;


@mixin neg {
  -webkit-font-smoothing: antialiased;
}
