.section-header {
  .logo-horizontal {
    display: block;
    font-size: 0;
    background-image: url('../../static/img/logo-mxi-horizontal.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    width: 100%;
    height: 100px;
    margin-bottom: 1em;

    @include media-breakpoint-down(sm) {
      height: 60px;
    }
  }

  .logo-area {
    margin: 1.5em 0 3.5em;

    @include media-breakpoint-down(md) {
      margin-bottom: 1.5em;
    }
  }
}
