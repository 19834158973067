.panel-toc {
  padding: 1.2em 0.8em 1.1em;
  background-color: $color-gray-light;
  margin: 1em 0 4em;

  ul {
    padding-left: 1.2em;
    li {
      padding-bottom: 0.1em;

      a {
        color: $color-indigo-dark;
      }
    }
  }
}
