html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  color: $color-black;
  -webkit-font-smoothing: antialiased;
  @include latoRegular;
  font-size: $base-font-size;
  line-height: $base-line-height;
  @include media-breakpoint-down(md) {
    font-size: 15px;
  }
}

.main {
  min-height: 100%;
}

h1, h2, h3, h4, h5 {
  @include latoBlack;
  text-transform: uppercase;
  color: $color-indigo-dark;
  margin-bottom: 0;
  line-height: 1.1;
}

h1 {
  font-size: 2.8rem;
  margin-bottom: 0.6em;
  margin-top: 0.3em;

  @include media-breakpoint-down(md) {
    font-size: 2.4rem;
  }
}

h2 {
  font-size: 2.25rem;
  margin-bottom: 0.4em;

  @include media-breakpoint-down(md) {
    font-size: 2.2rem;
  }
}

h3 {
  margin-bottom: 0.4em;
}

a {
  color: $color-violet-red;
  text-decoration: none;
  &:hover {
    color: $color-violet-red;
    text-decoration: underline;
  }
}

ul.bullets {
  padding-left: 1.75em;
  li {
    margin-bottom: 0.3em;
  }
}

.lead {
  @include latoBold;
  color: $color-indigo-dark;
}

.medium {
  @include latoBold;
  color: $color-indigo-dark;
  font-size: 1.1em;
}

strong, strong.medium {
  @include latoBlack;
}

.superstrong {
  @include latoBlack;
  color: $color-indigo-dark;
  text-transform: uppercase;
}

.subtitle {
  @include latoBlack;
  font-size: 1.35em;
  text-transform: uppercase;
  color: $color-indigo-dark;
  @include media-breakpoint-down(md) {
    font-size: 1.2em;
  }

  .subtitle-small {
    font-size: 0.85em;
  }
}

.highlighted {
  display: inline-block;
  background-color: $color-turquoise;
  color: black;
  padding: 0.2em 0.4em;
  font-weight: 700;
}

.mixed-case {
  text-transform: none;
}

a.btn {
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    text-decoration: none;
  }
}

@mixin btn-color($color-bg, $color-text: $color-white) {
  background-color: $color-bg;
  border-color: $color-bg;
  color: $color-text;

  &:hover {
    background-color: darken($color-bg, 10%);
    border-color: darken($color-bg, 10%);
    color: $color-text;
  }
}

.btn-primary {
  @include btn-color($color-indigo-dark);
}

.btn-highlighted {
  @include btn-color($color-violet-red);
}

.extra-padding {
  padding: 0 40px;
  @include media-breakpoint-up(xxxl) {
    padding: 0 100px;
  }
  @include media-breakpoint-down(xl) {
    padding: 0;
  }
}

.extra-padding-l {
  padding-left: 40px;
  @include media-breakpoint-up(xxxl) {
    padding-left: 100px;
  }
  @include media-breakpoint-down(xl) {
    padding-left: 0;
  }
}

.extra-padding-r {
  padding-right: 40px;
  @include media-breakpoint-up(xxxl) {
    padding-right: 100px;
  }
  @include media-breakpoint-down(xl) {
    padding-right: 0;
  }
}

.panel-padding {
  padding: 0 15px;
  @include media-breakpoint-up(xxxl) {
    padding-left: 70px;
  }
  @include media-breakpoint-down(xl) {
    padding: 0;
  }
}

.panel-warning {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1em;
  margin: 0.25em;
  .lead {
    color: #fb0577;
  }
}

.panel-bordered {
  padding: 1em;
  border: 10px solid $color-turquoise;
  margin: 0.25em;
}

.panel-info {
  padding: 0.7em;
  background-color: $color-gray-light;
}

.panel-dark {
  padding: 1.15em 1.65em;
  background-color: $color-indigo-dark;
  color: $color-white;
  h1, h2, h3, h4, h5 {
    color: $color-white;
  }
  .medium {
    color: $color-white;
  }
}

.panel-white {
  background-color: $color-white;
}

.mt-6 {
  margin-top: 5rem !important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}

.recording {
  border: 10px solid $color-turquoise;
}

.photo-speaker {
  border: 8px solid $color-indigo-dark;
}

.text-orange {
  color: $color-orange-dark;
}

.text-indigo {
  color: $color-indigo-dark;
}
