.section-footer {

  position: relative;
  margin-top: 5em;
  padding: 2em 0;
  background-color: $color-indigo-dark;
  color: $color-white;

  a {
    color: $color-white;
    &:hover {
      color: $color-white;
    }
  }
}

ul.footer-links {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.footer-credits {
  font-size: 14px;
}
