.countdown {
  margin: 0 auto;
  text-align: center;
  padding-top: 1em;
  .countdown-item {
    display: inline-block;
    text-align: center;
    background-color: $color-white;
    color: $color-indigo-dark;
    height: 5em;
    width: 5em;
    margin-right: 0.7em;
    margin-bottom: 0.7em;

    &:last-child {
      margin-right: 0;
    }

    .value {
      display: block;
      @include latoBlack;
      font-size: 2em;
      line-height: 1.2;
      margin-top: 0.3em;
    }
    .unit {
      display: block;
      font-size: 0.9em;
      @include latoBlack;
      text-transform: uppercase;
    }

    @include media-breakpoint-down(lg) {
      height: 4em;
      width: 4em;
      margin-right: 0.4em;
      margin-bottom: 0.4em;

      .value {
        font-size: 2em;
      }

      .unit {
        font-size: 0.75em;
      }
    }

    @include media-breakpoint-only(xs) {
      width: 3.6em;
      margin-right: 0.2em;
      .unit {
        font-size: 0.7em;
      }
    }
  }
}
