.animatedbg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-animation;
  pointer-events: none;

  iframe {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    width: 40%;
    height: 100%;
    border: none;
    border-left: 20px solid $color-white;
    border-right: 20px solid $color-white;

    @include media-breakpoint-down(xl) {
      width: 45%;
    }
  }
}
.animatedbg-placeholder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  width: 50%;
  height: 100%;
}
