ul.organizers {
  margin: 0;
  padding: 0;
  font-size: 0;

  li {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 30%;
    height: auto;
    margin-right: 5%;
    &:last-child {
      margin-right: 0;
    }

    @include media-breakpoint-down(md) {
      width: 45%;
      margin-bottom: 20px;
    }
  }
  .organizer-logo {
    display: block;
    @include image-replace;
    padding-bottom: 60%;
    background-size: contain;
    background-position: center center;
  }
  .ihp {
    @include image-replace-src('../../static/img/2022/logo-ihp-mp.svg', 100%, 0);
  }
  .momath {
    @include image-replace-src('../../static/img/2022/logo-momath.jpg', 100%, 0);
  }
  .imaginary {
    @include image-replace-src('../../static/img/2022/logo-imaginary-openmath.png', 100%, 0);
  }
  .srs {
    @include image-replace-src('../../static/img/2024/logo-srs.png', 100%, 0);
  }
}

.organizers-panel {
  position: relative;
  padding: 2em 0;
  background-color: $color-white;
}

ul.organizers-2024 {
  .srs {
    width: 90%;
  }

  .momath {
    width: 97%;
  }
}
