.session {
  margin-bottom: 3rem;

  .title {
    font-size: 1.5rem;
    margin-bottom: 0.1em;
  }

  .presenter {
    font-size: 1.33rem;
    font-weight: bold;
  }

  .affiliation {
    font-size: 1.33rem;
  }

  .description {
    margin-top: 1em;
  }
}
