.mainlogo-wrapper-2022 {
  max-width: 500px;
  margin: 0 auto;
}

.mainlogo-wrapper-2024 {
  max-width: 450px;
  margin: 0 auto;
}

.mainlogo-2022 {
  width: 100%;
  height: 0;
  padding: 0;
  padding-bottom: 86%;
  position: relative;
  margin: 0;
  background-image: url('../../static/img/2022/logo-matrix-x-imaginary-2022-colors.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0;
  border: none;
}

.mainlogo-2024 {
  width: 100%;
  height: 0;
  padding: 0;
  padding-bottom: 98%;
  position: relative;
  margin: 0;
  background-image: url('../../static/img/2024/mxi-2024-logo.svg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0;
  border: none;
}
