.nav {
  margin-bottom: 2em;
  margin-top: 1em;
  .nav-link {
    background-color: $color-violet-red;
    color: $color-white;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 900;
    margin-bottom: 0.25em;

    background-image: url('../../static/img/chevron-right.svg');
    background-repeat: no-repeat;
    background-position: 0.7em center;
    background-size: 0.85em;
    padding: 0.5em 1em 0.5em 2.1em;
  }
}
